import React from "react";
import "../styles/scss/home.scss";
import Container from "./Container";
import { useStaticQuery, graphql, Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Image from "gatsby-image";

const Home = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      avatar: file(absolutePath: { regex: "/profile-pic-large.jpg/" }) {
        childImageSharp {
          fixed(width: 550, height: 550, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name 
            summary 
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const avatar = data?.avatar?.childImageSharp?.fixed

  return (

    <section id="home">

      <div className="animation"> 
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>

      <Container>
       

          <div className="intro-container">
            
            <div className="intro row">

              
              <div className="col-12 col-lg-6">
                <h1>hello...</h1>
                <p className="intro-text"><strong>I'm a front end developer with over 20 years experience working in the marketing sector, playing with code and imagery. Located in <OutboundLink target="_blank" href="https://www.google.com/maps/d/embed?mid=1VrqWRglx0CbbA9pGo9dofAA-0_8&hl=en&ie=UTF8&t=p&msa=0&ll=51.600113766607095%2C-1.1248110000000056&spn=0.049581%2C0.087547&output=embed&z=14">Wallingford, Oxfordshire, UK.</OutboundLink></strong></p>
                <p>My specialty is email design &amp; builds and can maximise your ROI on email campaigns from using best practice approaches and learnings from past experience. I also enjoy building ecommerce websites using OpenCart, creating bespoke solutions.</p>

                <Link to="/contact">
                  <input type="submit" className="btn" value="Send me a message" />
                </Link>

              </div>
              <div className="col-12 col-lg-6">
                {avatar && (
                  <Image
                    fixed={avatar}
                    alt={author?.name || ``}
                    className="bio-avatar"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row roles">
            <div className="col-12 col-md-6 designer">
              <h2>graphic designer</h2>
              <p>Starting with a HND in Graphic Design in 1999 have adapted and mastered through various roles from exhibition, POS, brochure design to email design templates</p>
            </div>
            <div className="col-12 col-md-6 developer">
              <h2>&lt;front end developer&gt;</h2>
              <p>From 2015 I focused more time on the code side from creating Pharaceutical iPad apps to Wordpress and Angular theming on enterprise solutions</p>
            </div>
          </div>
      </Container>
    </section>
  )
}

export default Home