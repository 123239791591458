import React from "react"
import "../styles/scss/skills.scss"
import {HorizontalBar} from 'react-chartjs-2';

const Skills = () => {

  const data = {
    labels: ['Angular 2+', 'Bootstrap', 'CSS/SASS', 'jQuery', 'Wordpress', 'CSS animation', 'Email templates'],
    datasets: [
      {
        backgroundColor: '#575e8e',
        data: [70, 90, 92, 75, 90, 85, 99]
      }
    ]
  };

  return (
    <div className="skills container">
      <div className="row">
        <h2>my topline tech stack</h2>
        <div className="chart-container"> 
            <HorizontalBar 
            data={data}
            width={100}
            height={50}
            options={{ 
              maintainAspectRatio: false,
              aspectRatio: 2,
              backgroundColor: 'rgba(0, 0, 0, 1)',
              legend: {
                display: false,
              },
              tooltips: { 
                enabled: false
              },
              gridLines: {
                display: false
              },
              showLines: false,
              animation: {
                show: {
                    x: {
                        from: 0
                    },
                    y: {
                        from: 0
                    }
                },
                hide: {
                    x: {
                        to: 0
                    },
                    y: {
                        to: 0
                    }
                },
                duration: 4000,
                easing: 'easeOutQuart',
                onProgress: function(animation) {
                }
              },
              scales: {
                xAxes: [{
                    ticks: {
                      min: 50,
                      max: 100,
                      callback: function(value){return value+ "%"}, 
                      fontSize: 20,
                      fontFamily: "--fontFamily-serif",
                      defaultFontSize: 18,
                      fontColor: '#212529',
                      stepSize: 10
                    },  
                    scaleLabel: {
                      display: true,
                      labelString: "skill level",
                      defaultFontSize: 18, 
                    },
                    gridLines: {
                      color: "rgba(0, 0, 0, 0)",
                      display: false
                    }
                  }],
                  yAxes: [{
                    ticks: {
                      fontSize: 20,
                      fontFamily: "--fontFamily-serif",
                      defaultFontSize: 18,
                      fontColor: '#212529',
                      stepSize: 1
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                        display: false
                    },
                  }]
                },
                plugins: {
                  deferred: {
                    delay: 1000      // delay of 500 ms after the canvas is considered inside the viewport
                  }
                }
              }} 
              />
        </div>
      </div>
    </div>
  )
}

export default Skills