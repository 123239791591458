import React from "react"
import Helmet from "react-helmet"
import Banner from "../components/Banner"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Home from "../components/Home"
import Skills from "../components/Skills"

export default function IndexPage() {
  return (
    <div className="home">
      <Helmet
          htmlAttributes={{
            lang: 'en',
          }}>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://www.adrianbright.co.uk" />
        <title>Adrian Bright Front End Developer</title>
        <description>Adrian Bright Front End Developer </description>
      </Helmet>
      <Banner />
      <Header />
      <Home />
      <Skills />
      <Footer />
  </div>
  )
}
